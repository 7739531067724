import React, { useEffect } from "react"
import styled from 'styled-components';
import { SiteContext } from "context/site-context";
import Section from "components/Section/Section";
import Jellies from "modules/Jellies/Jellies";
import HtmlParser from "utils/HtmlParser/HtmlParser";

import { Link } from "gatsby"
import { fontstack } from 'utils/fontstack';
import { type } from 'utils/type';
import { media } from "utils/mediaQuery";

import Layout from "components/Layout/Layout"
import Seo from "../components/seo"

const AboutLayout = styled(Layout)`
  background: rgb(var(--theme-black));
`

const AboutSection = styled(Section)` 
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 16px;
  margin: 48px 0 0;

  ${media.small` 
    grid-template-columns: repeat(24, 1fr);
  `}
`

const MainColumn = styled.div` 
  color: rgb(var(--theme-primary));
  grid-column: 1 / span 6;

  ${media.small` 
    grid-column: 1 / span 12;
  `}

  ${media.large` 
    grid-column: 9 / span 8;
  `}

  ${media.xlarge`
    grid-column: 9 / span 6;
  `}
`

const JelliesAbout = styled(Jellies)` 
  margin: 48px 0;

  ${media.small` 
    display: block;
  `}
`

const BackButton = styled(Link)`
  ${fontstack.default}
  ${type('body')} 
  color: rgb(var(--theme-primary));

  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.2;
  }
`

const AboutPage = () => {
  const { cachedRoute } = React.useContext(SiteContext);
  useEffect(() => {
    document.body.setAttribute('data-theme', 'dark');
    return () => {
      document.body.setAttribute('data-theme', 'light');
    }
  }, [])

  return (
    <AboutLayout excludefooter omitFilter activeTag="about">
      <Seo title="About" />
      <AboutSection>
        <MainColumn>
          <HtmlParser>
          {'<p>Curated by <a href="https://www.davejreid.com/" target="_blank">David Reid</a> and built by <a href="https://www.manualengineering.com/" target="_blank">Manual Engineering</a>. If you’d like to get in touch, please message <a insta="true" href="https://ig.me/m/seensounds" target="_blank">@seensounds</a>.</p>'}
          </HtmlParser>
          <JelliesAbout />
          {cachedRoute.current && <BackButton to={cachedRoute.current}>← Back</BackButton>}
        </MainColumn>
      </AboutSection>
    </AboutLayout>
  )
}

export default AboutPage
